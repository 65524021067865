import API from '../Config/API'
import axios from 'axios';
import {isEmpty} from 'loadsh'
import  CONSTANTS  from '../Utils/Constants';
import { COUNTRY_LOCALIZATION_MAP } from '../Utils/countryConstants';
import { isObject } from 'lodash-es';
import {bannerDiy, eta4, lastMileDetailsForStatus, multiShipmentDiy, processFormDiy, trackingDiy} from './DiyPreivewDefaultData';
const getLangCode = ()=>{
  return sessionStorage.getItem("lang")
}

const toCamelCase=(str)=> {
  return str.split(" ").map((v,i)=>{v=v[0].toUpperCase()+v.substr(1).toLowerCase();return v;}).join(" ");
}

const updateLanguageCodeInResponseData = (resObj) =>{
  if(sessionStorage.getItem("lang")){ 
    if(isObject(resObj)){resObj.language= sessionStorage.getItem("lang")} 
  }else{
    if(isObject(resObj) && COUNTRY_LOCALIZATION_MAP[resObj?.language]){
      sessionStorage.setItem("lang",resObj?.language);
    }
  } 
}
class RestClientService {
    // tracklog with Google API
    async getTrackLogsFromServer(jobId, tracklogSource, userId, url, sourceId,dateTime) {
        try {
            if(url?.includes('preview')){
              return []
            }
            url = url.replace(/\s/g, "+");
            if (url.indexOf('&embed=true') != -1) {
                url = url.substring(0, url.indexOf('&embed=true'));
            }
            url = await this.updateUrl(url);
            if (tracklogSource && tracklogSource == "GTS" && sourceId) {
                userId = sourceId;
            }
            let hitUrl = API.TRACK_LOG;
            const res = await axios.get(hitUrl, { params: { userId: userId, jobId: jobId, date: dateTime, url: url, tracklogSource: tracklogSource, lang:getLangCode() } });
            return res.data;
        } catch (err) {
            return [];
        }
    }

    async postTrackLogsFromServer(jobId, tracklogSource, userId, url, sourceId, dateTime, cacheData, pmId, carrierCode) {
      try{
        if(url?.includes('preview')){return;}
        url = url.replace(/\s/g, "+");
        if (url.indexOf('&embed=true') != -1) {
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        if (tracklogSource && tracklogSource == "GTS" && sourceId) {
          userId = sourceId;
        }
        const resp = await axios.post(API.TRACK_LOG, {
          url: url,
          userId: userId,
          referenceDate: dateTime,
          jobId: jobId,
          trackLogSource: tracklogSource,
          carrierCode:carrierCode,
          cacheJson: !isEmpty(cacheData) ? JSON.stringify(cacheData): null
        });
        if(resp && resp.status == 200 && resp.data) {
          return resp.data;
        }

      } catch(err) {
        console.log("Error while posting trackLogs from server", err);
        return [];
      }
    }

    async getTripEtaFromServer(url,jobId,browserTimeZone, jobTransactionId){
      if(url?.includes("preview")){
        return;
      }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.GET_TRIP_ETA;
        const res = await  axios.get(hitUrl, {params: {url:url,jobId:jobId,browserTimeZone:browserTimeZone,jobTransactionId:jobTransactionId , lang:getLangCode()} });
        return res.data;
    }

    async getTrackingDetailsFromServer(url,deviceType, isReturn){
        try{
          if(url?.includes('preview')){
              const params  = new URLSearchParams(url);
              const companyId = params.get('companyId');
              const merchantCode = params.get('merchantCode');
              const deviceType = params.get('deviceType');
              if(companyId != undefined){
                try{
                  const newCiData =await axios.get(API.GET_NEW_CI_DIY_DATA,{params:{companyId,merchantCode:merchantCode||""}});
                  if(newCiData.status==200){
                    trackingDiy.customerInteractionThemeSettingsDTO.newCiData = newCiData.data;
                  }
                }
                catch(e){
                  console.log("get ci diy theme api fail!!");
                }
              }
              return {...trackingDiy,screen:deviceType=="mobile"?"NewDeviceLayout":"NewDesktopLayout"};
            }
            url = url.replace(/\s/g, "+");
            let embedUrl=false;
            if(url.indexOf('&embed=true')!= -1){
              url = url.substring(0, url.indexOf('&embed=true'));
              embedUrl = true;
            } else if((window.location.href).indexOf('&embed=true')!= -1){
              embedUrl = true;
            }
            url = await this.updateUrl(url);
            let hitUrl = isReturn? API.GET_RETURN_TRACKING_DETAILS:  API.GET_TRACKING_DETAILS;
            const universalTracking = window.location.href.includes(CONSTANTS.UNIVERSAL_TRACKING)?true:false;
            const res = await  axios.get(hitUrl, {params: {url: url,deviceType:deviceType,embedUrl:embedUrl, lang:getLangCode(),universalTracking:universalTracking} });
            if(res?.data && (res.data.screen == "OrderDeskView" || res.data.screen == "OrderDeviceView")){
              try{
                const feRes = await axios.get(API.GET_STATE_FROM_FE_STACK_SHIPMENT,{params:{url,companyId:res.data.companyId}});
                if(res.data.orderLevelLayoutScreenDTO!=null && res.data.orderLevelLayoutScreenDTO.attributeList !=null){
                  res.data.orderLevelLayoutScreenDTO.attributeList=[{label:"Order Status",value:toCamelCase(feRes.data)},...res.data.orderLevelLayoutScreenDTO.attributeList];
                }
              }
              catch(e){
                  console.log(e.message);
              }
            }
            window.localStorage.setItem('isHerePoweredAccount',res.data.herePoweredAccount);
            if(res.data && typeof res.data == "string" && res.data.indexOf("redirectUrl")>-1){
              const myArray = res.data.split("$$$");
              location.replace(myArray[1]);
            }
            if(res.data && res.data.customerInteractionThemeSettingsDTO && res.data.customerInteractionThemeSettingsDTO.newCiData){
              res.data.customerInteractionThemeSettingsDTO.newCiData=JSON.parse(res.data.customerInteractionThemeSettingsDTO.newCiData);
            }
            return res.data;
          } catch(err){
             return "notFound";
          }
    }

    async getMultiShipmentDetailFromServer(url,deviceType, isReturn){
      try{
          if(url?.includes('preview')){
            return multiShipmentDiy;
          }
          url = url.replace(/\s/g, "+");
          let embedUrl=false;
          if(url.indexOf('&embed=true')!= -1){
            url = url.substring(0, url.indexOf('&embed=true'));
            embedUrl = true;
          } else if((window.location.href).indexOf('&embed=true')!= -1){
            embedUrl = true;
          }
          url = await this.updateUrl(url);
          let hitUrl = API.GET_MULTI_SHIPMENT_TRACKING_DETAILS;
          const res = await  axios.get(hitUrl, {params: {url: url,deviceType:deviceType,embedUrl:embedUrl, lang:getLangCode(),isReturn:isReturn} });
          return res.data;
        } catch(err){
           return "notFound";
        }
  }

    async getProcessUpdateFormDetails(url,deviceType,isReturn){
      try{
          if(url?.includes('preview')){
            return processFormDiy;
          }
          url = url.replace(/\s/g, "+");
          if(url.indexOf('&embed=true')!= -1 || (window.location.href).indexOf('&embed=true')!= -1){
            return;
          }
          url = await this.updateUrl(url);
          let hitUrl = API.GET_PROCESS_UPDATE_FORMS;
          const universalTracking = window.location.href.includes(CONSTANTS.UNIVERSAL_TRACKING)?true:false;
          const res = await  axios.get(hitUrl, {params: {url: url,deviceType:deviceType,lang:getLangCode(),isReturn:isReturn,universalTracking:universalTracking} });
          window.localStorage.setItem('isHerePoweredAccount',res.data.herePoweredAccount);
          return res.data;
        } catch(err){
           return "notFound";
        }
  }
    async saveData(data,url,slotNumber,slotList) {
      if(url?.includes("preview")){
        return;
      }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
         url = url.substring(0, url.indexOf('&embed=true'));
       }
       url = await this.updateUrl(url);
        if(!isEmpty(slotList)){
         slotList = JSON.stringify(slotList)
        }
        let json = data;
        let hitUrl =API.SAVE_DATA;
        const res = await  axios.post(hitUrl, json ,{params: {url:url,slotNumber:slotNumber,slotList:slotList,lang:getLangCode()}});
        return res.data;
     }

     async searchAddressFromServer(address,url, isHerePoweredAccount){
      if(url?.includes("preview")){
        return;
      }
       if(isHerePoweredAccount){
        let results = "";
        if(results.items[0]){
          let position = results.items[0].position;
          let data  = {};
          data.latitude = position.lat;
          data.longitude = position.lng;
          data.address = results.items[0].address;
          data.formatted_address = results.items[0].address.label;
          return data;
        }
       }
       else{
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.SEARCH_ADDRESS;
        const res = await  axios.get(hitUrl, {params: {address:address,url: url, lang:getLangCode()} });
        return res.data;
      }
      }
      async getPaymentOrderDetailsFromServer(url,orderId){
        let hitUrl =API.GET_PAYMENT_ORDER_DETAILS;
        if(url){
          url = url.replace(/\s/g, "+");
          if(url.indexOf('&embed=true')!= -1){
            url = url.substring(0, url.indexOf('&embed=true'));
          }
          const res = await  axios.get(hitUrl, {params: {url: url,paymentReferenceId:null,lang:getLangCode()} });
          return res.data;
        } else {
          const res = await  axios.get(hitUrl, {params: {url: null,paymentReferenceId:orderId, lang:getLangCode()} });
          return res.data;
        }
      }
      async getSlotFromServer(data,url){
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let jso = JSON.stringify(data.processInteractionKeysDtos);
        let order =JSON.stringify(data.ordersForAutoAssign)
        let hitUrl =API.GET_SLOTS;
        const res = await  axios.get(hitUrl, {params: {data:jso,url: url,id:data.hubId,order:order, lang:getLangCode()} });
        return res.data;
      }

      async updateLocation(url,lat,lng,message){
        if(url?.includes("preview")){
          return;
        }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        lat = lat?lat.toFixed(3):'';
        lng = lat?lng.toFixed(3):'';//is this correct
        let hitUrl =API.UPDATE_LOCATION;
        const res = await  axios.post(hitUrl, null ,{params: {url:url,lat:lat,lng:lng,message:message}});
        return res.data;
      }
      async getHolidayMaster(url,hubId){
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.GET_HOLIDAY;
        const res = await  axios.get(hitUrl, {params: {hubId:hubId,url: url, lang:getLangCode()} });
        return res.data;
      }

      async updateProcessOtherDataForm(processInteractionKeysDtos,masterCode,ref,url,title,dynamicSlotDate,dynamicSlots,isPayment,productAmount,pudo,type,isReturn, companyId){
        let redirectUrl = location.origin;
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        processInteractionKeysDtos = await this.updateAttributeWithImageUrl(processInteractionKeysDtos, companyId);
        let start,end;
        if(!isEmpty(dynamicSlotDate) && !isEmpty(dynamicSlots)){
            start = dynamicSlots.start;
            end = dynamicSlots.end;
        }
        if(isEmpty(productAmount) || productAmount == "0"){
          isPayment = false;
        }
        let hitUrl =(type && type==CONSTANTS.CURBSIDE_PICKUP)?API.UPDATE_CURBSIDE_PICKUP_PROCESS_FORM:API.UPDATE_PROCESS_FORM;
        title = !isEmpty(title)?encodeURIComponent(title):"";
        try{
          const res = await  axios.post(hitUrl, processInteractionKeysDtos ,{
            headers:{"Content-type": "application/json; charset=UTF-8"},
            params: {url:url,code:masterCode,ref:ref,form:title,slotDate:dynamicSlotDate,start:start,end:end,isPayment:isPayment,productAmount:productAmount,redirectUrl:redirectUrl,pudo:JSON.stringify(pudo),type:type, lang:getLangCode(),isReturn:isReturn}});
           if(res.data != "" && res.data!="OK"){
              if(isEmpty(res.data.status)){
                  window.open(res.data,"_self");
              }
           }
          if (res.data!="" && res.data.initiatedTime!=undefined){
                return res.data;
           } 
             return res.status;
           
        }catch(err){
            console.log("error",err);
            if(err && err.response && err.response.data && ((err.response.data).includes("[CI]"))){
              return err.response.data;
            }
            return null;
        }
       
    }

    async updateProcessForm(processInteractionDTO,dynamicSlotList,dynamicSlots,processUrl, selectedSlotFenceId){
      let hitUrl =API.UPDATE_PROCESS_FORM_FOR_3PL;
      try{
        const res = await axios.post(hitUrl, {
          processInteractionDto:processInteractionDTO,
          availableSlotDtoList:dynamicSlotList,
          bookDynamicSlotNumber:dynamicSlots.slotNumber,
          fenceId:selectedSlotFenceId
      },{params:{processUrl}});
           return res;
      }catch(err){
          console.log("error",err);
          throw err;
      }

  }

    async cancelParallelPayments(url) {
        url = url.replace(/\s/g, "+");
        if (url.indexOf('&embed=true') != -1) {
            url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl = API.CANCEL_ORDER;
        const res = await axios.get(hitUrl, { params: { url: url } });
        return res.status;
    }
    async sendMessage(url, orderId, message, userId) {
      if(url?.includes("preview")){
        return;
      }
        url = url.replace(/\s/g, "+");
        if (url.indexOf('&embed=true') != -1) {
            url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl = API.SEND_MESSAGE;
        const res = await axios.post(hitUrl, null, { params: { url: url, orderId: orderId, message: message, userId: userId } });
        return res.data;
    }
    async saveFeedback(url,feedbackObj, type,timeStampKey,timeStampValue,isReturn){
      if(url?.includes("preview")){
        return;
      }
      try{
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.SAVE_FEEDBACK;
        if (type && type === CONSTANTS.CURBSIDE_PICKUP) {
          hitUrl = API.SAVE_CURBSIDE_FEEDBACK;
        }
        // if(!isEmpty(comment)){
        //   reasons = JSON.stringify(reasons);
        // }

          const res = await  axios.post(hitUrl, feedbackObj ,{params: {url:url,timeStampKey:timeStampKey,timeStampValue:timeStampValue, lang:getLangCode(),isReturn:isReturn?isReturn:false}});
          return res.status;
        }
        catch(err){
          console.log("save feedback err",err);
        }
      }

      // async getDynamicSlot(url,date,referenceNumber,masterCode,id,baseDate,min,max){
      //   url = url.replace(/\s/g, "+");
      //   if(url.indexOf('&embed=true')!= -1){
      //     url = url.substring(0, url.indexOf('&embed=true'));
      //   }
      //   let hitUrl =API.GET_DYNAMIC_SLOT;
      //   const res = await  axios.get(hitUrl, {params: {url:url,date:date,referenceNumber:referenceNumber,code:masterCode,baseDate:baseDate,id:id,min:min,max:max} });
      //   return res.data;
      // }

      async getDynamicSlot(processInteractionDTO,processUrl){
        let hitUrl =API.GET_3PL_DYNAMIC_SLOT;
        const res = await axios.post(hitUrl, processInteractionDTO,{params:{processUrl}});
        return res.data;
      }

      async fetchAvailableDatesFromSlotBookingCoordinator(processUrl,processInteractionDTO){
        let hitUrl =API.GET_AVAILABLE_DATES_FROM_cOORDINATOR;
        const res = await axios.post(hitUrl, processInteractionDTO,{params:{processUrl}});
        return res.data;
      }
      async saveFeedbackForShipment(url,feedbackObj, type,timeStampKey,timeStampValue){
        if(url?.includes("preview")){
          return;
        }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.SAVE_FEEDBACK_TO_SHIPMENT;
        const res = await  axios.post(hitUrl, feedbackObj ,{params: {url:url,timeStampKey:timeStampKey,timeStampValue:timeStampValue, lang:getLangCode()}});
        return res.status;
      }

      async getMarketingAssets(url, curbsidePickup, isReturn){
        if(url?.includes('preview')){
          return bannerDiy;
        }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        let hitUrl =API.GET_BANNER_LIST;
        if (curbsidePickup) {
          hitUrl =API.GET_CURBSIDE_PICKUP_BANNER_LIST;
        }
        const res = await  axios.get(hitUrl, {params: {url:url, isReturn: isReturn?true:false} });
        if(res && res.data){
          if(typeof res.data["bannerDetails"] != 'string')
          {res.data["bannerDetails"]=JSON.stringify(res.data["bannerDetails"]);}
          if(typeof res.data["carouselDetails"] != 'string'){
          res.data["carouselDetails"]=JSON.stringify(res.data["carouselDetails"]);}
        }
        if(res.status == 200) {
          return res.data;
        }
      }


      async getTrackLogByUserId(userId,url,dateTime,jobId, trackLogSource){
        try{
          if(url?.includes("preview")){return;}
          if(dateTime==undefined)return;
          url = url.replace(/\s/g, "+");
          if(url.indexOf('&embed=true')!= -1){
            url = url.substring(0, url.indexOf('&embed=true'));
          }
          url = await this.updateUrl(url);
          let hitUrl = API.GET_TRACK_LOG_BY_USER;
          const res = await axios.get(hitUrl, { params: { userId: userId, date: dateTime, url: url,jobId:jobId, trackLogSource:trackLogSource } });
           return res.data;
        }catch(err){
            return [];
        }
      }

      async postTrackLogsByUserId(userId,url,dateTime,jobId, trackLogSource, cacheData) {
        try {
          if(url?.includes("preview")){
            return;
          }
          if(dateTime==undefined)return;
          url = url.replace(/\s/g, "+");
          if(url.indexOf('&embed=true')!= -1){
            url = url.substring(0, url.indexOf('&embed=true'));
          }
          url = await this.updateUrl(url);
          const resp = await axios.post(API.POST_TRACK_LOG_BY_USER, {
            url: url,
            userId: userId,
            referenceDate: dateTime,
            jobId: jobId,
            trackLogSource: trackLogSource,
            cacheJson: !isEmpty(cacheData) ? JSON.stringify(cacheData): null
          });

          if(resp && resp.status == 200 && resp.data) {
            return resp.data;
          }
        } catch(err) {
          console.log("Error received while posting data ", err);
        }
        return {};
      }

      async fetchHolidayMaster(url, masterCode) {
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await this.updateUrl(url);
        const hitUrl = API.GET_HOLIDAY_MASTER_BY_CODE;
        const res = await axios.get(hitUrl, {params: {url, masterCode}});
        return res.data;
      }
      async getLastMileAndStatusDetails(url, isReturn,isMultiShipment){
        try{
            if(url?.includes('preview')){
              const param = new URLSearchParams(url);
              const status = param.get("status");
              const deviceType = param.get("deviceType");
              return lastMileDetailsForStatus(status||'unseen',deviceType);
            }
            url = url.replace(/\s/g, "+");
            if(url.indexOf('&embed=true')!= -1){
              return;
            }
            url = await this.updateUrl(url);
            let hitUrl =API.GET_LAST_MILE_AND_STATUS_DETAIL;
            const universalTracking = window.location.href.includes(CONSTANTS.UNIVERSAL_TRACKING)?true:false;
            const res = await  axios.get(hitUrl, {params: {url: url, isReturn: isReturn?true:false, lang:getLangCode(),isMultiShipment:isMultiShipment,universalTracking:universalTracking} });
            if(res && res.status=="200" && res.data){
              return res.data;
            }
            return "notFound";
          } catch(err){
             return "notFound";
          }
    }

    async getEta(url, isReturn,status,statusCategory){
      try{
          if(url?.includes("preview")){
            return eta4;
          }
          url = url.replace(/\s/g, "+");
          if(url.indexOf('&embed=true')!= -1){
            return;
          }
          url = await this.updateUrl(url);
          let hitUrl =API.GET_ETA;
          const res = await  axios.get(hitUrl, {params: {url: url, isReturn: isReturn?true:false,status:status,statusCategory:statusCategory, lang:getLangCode()} });
          if(res && res.status=="200" && res.data){
            return res.data;
          }
          return "notFound";
        } catch(err){
           return "notFound";
        }
  }

  async fetchPreviousJobsInfo(url, jobId, jobTransactionId, statusListToExclude) {
    try {
      if(url?.includes("preview")){
        return;
      }
      url = url.replace(/\s/g, "+");
      if (url.indexOf('&embed=true') != -1) {
        url = url.substring(0, url.indexOf('&embed=true'));
      }
      url = await this.updateUrl(url);
      const hiturl = API.GET_PREVIOUS_JOBS_INFO;
      if(!isEmpty(statusListToExclude)) {
        statusListToExclude = statusListToExclude.toString();
      }
      const res = await axios.get(
        hiturl,
        {
          params: {
            url: url,
            jobId: jobId,
            jobTransactionId: jobTransactionId,
            statusListToExclude: statusListToExclude
          }
        }
      );
      if (res.status == 200) {
        return res.data;
      }
      return null;
    } catch (err) {
      console.log("error", err)
      return null;
    }
  }

  async fetchFeedbackInfo(url, type,isReturn){
    try{
        if(url?.includes("preview")){
          return;
        }
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          return;
        }
        url = await this.updateUrl(url);
        let hitUrl =API.FETCH_SAVED_FEEDBACK_INFO;
        if (type && type === CONSTANTS.CURBSIDE_PICKUP) {
          hitUrl = API.FETCH_SAVED_CURBSIDE_FEEDBACK_INFO;
        }
        const res = await  axios.get(hitUrl, {params: {url: url,isReturn:isReturn} });
        if(res && res.status=="200" && res.data){
          return res.data;
        }
        return;
      } catch(err){
         return;
      }
}
  async getCurbsidePickupDetail(url){
    if(url?.includes("preview")){
      return;
    }
    url = url.replace(/\s/g, "+");
    if(url.indexOf('&embed=true')!= -1){
      url = url.substring(0, url.indexOf('&embed=true'));
    }
    url = await this.updateUrl(url);
    let hitUrl =API.GET_CURBSIDE_PICKUP_DETAIL;
    const res = await  axios.get(hitUrl, {params: {url:url} });
    if(res.status == 200) {
      return res.data;
    }
  }

  async updateUrl(url){
    if(url.indexOf('&gtm_debug')!= -1){
      return url = url.substring(0, url.indexOf('&gtm_debug'));
    }else {
      return url;
    }
  }

  async getShipmentTrackingDetailsFromServer(url,deviceType, shipmentRef,shipmentPmId){
    try{
      if(url?.includes("preview")){
        return;
      }
        url = url.replace(/\s/g, "+");
        let embedUrl=false;
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
          embedUrl = true;
        } else if((window.location.href).indexOf('&embed=true')!= -1){
          embedUrl = true;
        }
        url = await this.updateUrl(url);
        let hitUrl = API.GET_SHIPMENT_TRACKING_DETAILS;
        const res = await  axios.get(hitUrl, {params: { url: url,deviceType:deviceType,shipmentRef:shipmentRef,shipmentPmId:shipmentPmId,lang:getLangCode()} });
        window.localStorage.setItem('isHerePoweredAccount',res.data.herePoweredAccount);
        return res.data;
      } catch(err){
        console.log("errrorrrr",err);
         return "notFound";
      }
}
  async updateAttributeWithImageUrl(processInteractionKeysDtos, companyId) {
    for (let i = 0; i < processInteractionKeysDtos.length; i++) {
      if (processInteractionKeysDtos[i].attributeInputType == "imageUpload") {
        let response = await this.saveImage(processInteractionKeysDtos[i].value, companyId);
        if (response && response.status == 200 && !isEmpty(response.data)) {
          processInteractionKeysDtos[i].value = response.data;
        }
        else {
          console.log("Error uploading image");
          processInteractionKeysDtos[i].value = "";
        }
      }
    }
    return processInteractionKeysDtos;
  }

  async saveImage(file, companyId) {
    let hitUrl = API.UPLOAD_IMAGE;
    let requestBody = new FormData();

    requestBody.append("imageFile", file);
    requestBody.append("companyId", companyId);

    try {

      const res = await axios
        .post(hitUrl, requestBody, { headers: { 'Content-Type': 'multipart/form-data' } })
        .catch((err) => {
          console.error("exception in uploading image", err);
          return err;
        });
      return res;
    }
    catch (e) {
      console.log("EXception in here value to ", err)
    }
  }
}

export const restClientService = new RestClientService();