import { Box, TextField } from "@material-ui/core";
import { Button, Input } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { universalSearchService } from "./services/UniversalSearchService";
import { cloneDeep } from "lodash";
import { getBorderValue } from "../../Utils/themeConfig";
import { useDispatch } from "react-redux";
import { fetchUniversalTrackingLinks } from "./UniversalSearchAction";
import { setState } from "../../Utils/setState";
import UNIVERSAL_SEARCH from "../../Utils/universalSearchConstants";

const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
// function modifyTheResponse(data) {
//     if (isEmpty(data)) {
//         return "";
//     }
//     const cleanedInput = data.slice(1, -1);
//     const values = cleanedInput.split(",");
//     return values[0];

// }
//  function onSearch(paramsList, encodedString,fetchUniversalTrackingLinks) {
//     console.log("function being called ");
//      fetchUniversalTrackingLinks(paramsList, encodedString)
// }
function openCiLink(ci_link,openInSameTab,dispatch){
    const url = new URL(ci_link);
    const params = new URLSearchParams(url.search);
    const langCode = sessionStorage.getItem("lang");
    const tab = openInSameTab?'_self':'_blank';
    params.append('lang', langCode);
    url.search = params.toString();
    window.open(url,tab, 'noopener');
    dispatch(setState(UNIVERSAL_SEARCH.CI_LINK,"" ));    
}
// function isValidURL(url) {
//     return regex.test(url);
// }

function isDisabled(paramsList, key) {
    if (isEmpty(paramsList[key])) {
        return false;
    }
    return true;
}
 function prepareInputFields(combinationIndex,props){
    let attributeList=[];
    if(props.universalSettingType=="COMBINATION" && props?.aggregatedAttributeList[combinationIndex]?.combinationAttributekeyList){
    props.aggregatedAttributeList[combinationIndex].combinationAttributekeyList.forEach(element => {
        const attribute = props.universalConfigAttributeList.find((item)=>item.key==element)
        if(attribute){
            attributeList.push(attribute);
        }
    });}
    else attributeList= props.universalConfigAttributeList;
    return attributeList;
}

function isButtonDisable(paramsList, attributeList){
if(!isEmpty(paramsList) && !isEmpty(attributeList)){
    for(let i=0 ;i<attributeList.length;i++){
        let tempNode= "param"+(i+1);
        if(isEmpty(paramsList[tempNode])){
            return true;
        }
    }
    return false;

}
return true;
}
function InputFields(props) {
    const dispatch = useDispatch();
    const onSearch = (paramsList,encodedString) => {
        dispatch(fetchUniversalTrackingLinks(paramsList,encodedString, combinationIndex,props.universalSettingType));
    }
    const [paramsList, setParamList] = useState(props.paramsList);
    const [combinationIndex, setCombinationIndex] = useState(0);
    
    const attributeList= prepareInputFields(combinationIndex,props);
    const inputList =!isEmpty(attributeList) && attributeList.map((value, index) => {
            return (<div>
                {!isDisabled(props.paramsList, "param" + (index + 1)) &&
                <TextField className="universal-searchFields"
                    placeholder={value.label}
                    type="string"
                    value={paramsList["param" + (index + 1)] ||""}
                    onChange={(e) => {
                        let tempParamList = cloneDeep(paramsList);
                        let key = "param" + (index + 1);
                        tempParamList[key] = e.target.value;
                        setParamList(tempParamList);
                    }}
                    disabled={isDisabled(props.paramsList, "param" + (index + 1))}
                    label={value.label}
                    variant="outlined"
                    InputLabelProps={{
                        style: {fontFamily:`${props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`},
                    }}
                    InputProps={{
                        style: {fontFamily:`${props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`},
                    }}
                />}

            </div>)
        })
    const combinationButtons = !isEmpty(props.aggregatedAttributeList) && props.aggregatedAttributeList.map((combination, index) => {
        return (<>
            <button
                key={index}
                onClick={() => handleToggle(index)}
                style={{
                    backgroundColor: combinationIndex === index ? props.themeData?.primaryBgColor : "white",
                    color: combinationIndex === index ? props?.themeData?.ctaFontColor : "black",
                    padding: "10px 20px",
                    border:"none",
                    borderRight:`1px solid ${props.themeData?.primaryBgColor}`,
                    cursor: "pointer",
                    flex:"auto",
                    minHeight:"40px",
                    fontFamily:`${props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif}`,

                }}
            >
                {combination.combinationName}
            </button>
        </>
        )
    })
    const card = props.newThemeData?.universalSearch?.card?.default ? props.newThemeData?.defaultSettings?.card:props.newThemeData?.universalSearch?.card 
    const universalTheme = props.newThemeData?.universalSearch;
    const border = getBorderValue(card?.borderColour||'');
    const buttons = ["Button 1", "Button 2", "Button 3"];
    const handleToggle = (index) => {
        setParamList(props.paramsList);
        setCombinationIndex(index);
      };
    return (
        <div className="universal-search" style={{
            backgroundColor:card?.cardBgcolour||"white",
            borderRadius:`${card?.cornerRedius||8}px`,
            borderTop:border[0],
            borderRight:border[1],
            borderBottom:border[2],
            borderLeft:border[3],
            boxShadow: card?.shadow
        }}>
            <div className="universal-serach-header">
                <p className="universal-serach-heading" style={{
                    color: universalTheme?.heading?.fontColour,
                    fontSize: universalTheme?.heading?.fontSize+"px",
                    fontFamily: `${universalTheme?.heading?.fontType||props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                    fontWeight: universalTheme?.heading?.fontWeight,
                }}
                
                >{universalTheme?.heading?.label || containerConstants.formatString(containerConstants.TrackShipment)}</p>
                <p className="header-description" style={{
                    color: universalTheme?.description?.fontColour,
                    fontSize: universalTheme?.description?.fontSize+"px",
                    fontFamily: `${universalTheme?.description?.fontType||props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                    fontWeight: universalTheme?.description?.fontWeight,
                }}>{universalTheme?.description?.label || containerConstants.formatString(containerConstants.TrackingDescription)}</p>
            </div>
            {!isEmpty(props.aggregatedAttributeList) && <div className={"mb32"}style={{ display: "flex", marginBottom:"32px", border:`1px solid  ${props.themeData?.primaryBgColor}`, borderRadius:"8px", overflow:"hidden"}}>
                 {combinationButtons}
            </div>}
            {inputList}
            {props.ciLink=="ERROR"?<p className="mb15" style={{color:"#da1e28"}}>{containerConstants.formatString(containerConstants.InvalidCredentials)}</p>:!isEmpty(props.ciLink)?openCiLink(props.ciLink,universalTheme?.universalTracking?.openInSameTab,dispatch):""}
            <div onClick={() => { onSearch(paramsList, props.encodedString) }}>
                <Button className="universal-search-button"
                    style={{ 
                        color: isButtonDisable(paramsList,attributeList )?"#647788" :universalTheme?.button?.fontColour || props?.themeData?.ctaFontColor || "",
                        backgroundColor: isButtonDisable(paramsList,attributeList )?"#D6DADE" : universalTheme?.button?.buttonColor || (props.themeData?.ctaColorWhite ? "#fffff" : props.themeData?.primaryBgColor),
                        fontWeight:universalTheme?.button?.fontWeight,
                        fontFamily:`${universalTheme?.button?.fontType||props.newThemeData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`
                    }}
                    disabled={
                        isButtonDisable(paramsList,attributeList )}
                >
                    {universalTheme?.button?.label || containerConstants.formatString(containerConstants.Continue)}
                </Button></div>
        </div>
    )
}
export default InputFields;