export const getBorderValue=(val)=>{
    let border = ['','','',''];
    if(val?.length){
        const borderVal = val?.split(',')?.map(val=>val.trim()); 
        if(borderVal.length==1){
            border=[borderVal[0],borderVal[0],borderVal[0],borderVal[0]];
        }   
        else if(borderVal.length == 2){
            border=[borderVal[0],borderVal[1],borderVal[0],borderVal[1]];
        }
        else{
            borderVal.forEach((element,index) => {
                if(element!='0'&&index<4)border[index]=element;
            });
        }   
    }
    return border;
}

export const getFontList=(newCiData)=>{
    let fonts={[newCiData?.defaultSettings?.typeface?.fontType || "Roboto"]:0};
    fonts[newCiData?.eta?.etaHeading?.fontType||"Roboto"]=0;         //Eta heading font type
    fonts[newCiData?.eta?.eddValue?.fontType||"Roboto"]=0;         //Eta edd value font type
    fonts[newCiData?.timeLine?.headerText?.fontType||"Roboto"]=0;         //Tracker time line heading font type
    fonts[newCiData?.orderInfo?.headerText?.fontType||"Roboto"]=0;         //Order info heading font type
    fonts[newCiData?.pod?.headerText?.fontType||"Roboto"]=0;         //Pod heading font type
    fonts[newCiData?.universalSearch?.heading?.fontType||"Roboto"]=0;         //Universal heading font type
    fonts[newCiData?.universalSearch?.button?.fontType||"Roboto"]=0;         //Universal button font type
    fonts[newCiData?.universalSearch?.description?.fontType||"Roboto"]=0;         //Universal description font type
    let str="";
    for(let i in fonts){
        str+=i.replaceAll(' ','+')+'|';
    }
    return str;
}

