const frenchStrings = {
  YourShipmentHasBeenDelivered: "Votre colis a été livré",
  DeliveredBy: "Livré par",
  RateYourExperience: "Évaluez votre expérience",
  ThankYouForYourValuableFeedback: "Merci pour vos précieux commentaires",
  YourFeedbackWillContinuallyHelpUsImproveOurServices:
    "Ils nous permettent d’améliorer nos services continuellement.",
  DeliveryFailed: "Échec de la livraison",
  AddDeliveryPreferences: "Ajoutez des préférences relative à la livraison",
  YourDeliveryPreferenceAreSavedSuccessFully:
    "Vos préférences relatives à la livraison sont sauvegardées avec succès",
  WeAreUnableToTrackYourShipmentRightNow:
    "Nous ne sommes pas en mesure de suivre votre colis pour le moment",
  PleaseUpdateLocationInsideCircle:
    "Veuillez mettre à jour vos coordonnées à l intérieur du cercle",
  Ok: "D’accord",
  Update: "Mise à jour",
  PickCurrentLocation: "Choisissez le lieu actuel",
  SearchFor: "Recherchez",
  ThisSchedulingLinkHasExpired: "Ce lien de programmation a expiré",
  WeWillShareANewLinkWithYouShortly:
    "Nous vous communiquerons bientôt un nouveau lien",
  UhHo: "Uh ho !",
  NeedHelp: "BESOIN D AIDE ?",
  CallSupport: "Appelez le service d assistance",
  EmailSupport: "Assistance par courriel",
  DeliveryAt: "Livraison à",
  DeliveredAt: 'Livré à',
  ContactNo: "Numéro de contact",
  TrackOnMap: "Suivi sur la carte",
  MessageToExecutive: "Message à I exécutif",
  Cancel: "Annulez",
  Send: "Envoyez",
  Executive: "Exécutif",
  Comments: "Commentaires (Optionnels)",
  SubmitFeedback: "SOUMETTEZ VOS COMMENTAIRES",
  TrackingNo: "Numéro de commande",
  Details: "Détails",
  Characters: "Caractères",
  Updating: "mise à jour.....",
  OopsSomethingWentWrongPleaseTryAfterSomeTime:
    "Oups ! Quelque chose ne va pas, veuillez réessayer plus tard.",
  NotListedAboveSendACustomMessage:
    "Si vous n êtes pas dans la liste ci-dessus, envoyez un message personnalisé ",
  ChangeDeliveryLocation: "Modifier le lieu de livraison",
  AttemptedBy: "Tentative de livraison par",
  DeliveredTo: "Livré à ",
  SendMessage: "ENVOYEZ UN MESSAGE",
  VIEW_DETAILS: "Afficher les détails",
  SAVE_LOCATION: "SAUVEGARDER LA LOCALISATION",
  LOCATION: "localisation",
  SEARCH_ADDRESS: "Adresse de recherche",
  OpenHours: "Heures douverture",
  Note: "Remarque",
  Address: "Adresse",
  Nearest: "Proximité",
  ContactNumber: "Numéro de contact",
  FAQS: "FAQs",
  HowManyTimesCanIChangeMyPickUpPoint:
    "Combien de fois puis-je changer mon point de ramassage ?",
  YouCanChangeItOnly: "Vous ne pouvez le modifier que",
  GoBack: "Retournez",
  SetAsPickupStore: "Définir comme magasin de ramassage",
  MapView: "vue cartographique",
  SearchForPickupPointNearYou:
    "Recherchez le point de ramassage le plus proche de chez vous",
  Arriving: "Arrivée",
  LiveTrackingWillStart: "Le suivi en direct va commencer",
  DriverTrackerWillGetActivatedOnceTheyAreOnWay:
    "Le traceur du conducteur sera activé une fois quils seront en route",
  DeliveryBy: "Livraison par",
  TrackerTimeline: "Suivi de colis",
  PM: "APRES-MIDI",
  AM: "MATIN",
  TodayAt: "aujourd hui à",
  TomorrowAt: "demain à",
  Today: "Aujourd hui",
  Tomorrow: "Demain",
  On: "sur",
  In: "dans",
  Soon: "bientôt",
  Min: "min",
  Mins: "mins",
  Items: "POINTS",
  ViewDetails: "Afficher les détails",
  DeliveryInformation: "Informations sur la livraison",
  TrackingHistory: "Suivi de l historique",
  StayConnected: "Restez connecté",
  YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes:"Vous avez déjà tenté d effectuer un paiement au cours des dernières minutes. Souhaitez-vous annuler des opérations précédentes?",
  No: "NON",
  yes: "OUI",
  YourPackageWillBeDeliveredBy: "Votre colis sera livré par",
  OrderDetails: "détails de la commande",
  OrderInformation: "Informations sur la commande",
  LastAttempted: "Dernière tentative de livraison",
  Pickuppointisupdated:'Le point de ramassage est mis à jour',
  MsgToFePageHeader:'Envoyez les instructions de livraison',
  MsgToFeSupportingText:'Que voulez-vous dire au chauffeur ?',
  AddYourMsgHere:'Ajoutez votre message ici',
  InstructionsSentSuccessfully:'Instructions envoyées avec succès',
  SomethingWentWrongPlsTryAgain:'Quelque chose s’est mal passé, veuillez réessayer après un certain temps',
  times:"fois",
  TrackMovementInRealTime:"Suivre les mouvements en temps réel",
  Hours:"hours",
  Hour:"hour",
  WriteYourFeedbackOptional:"Écrivez vos commentaires (facultatif)",
  ReturnOrder: "Return Order",
  TheLinkYouAreTryingToOpenHasExpired: "Le lien que vous essayez d'ouvrir a expiré  ",
  SelectAnAvailableTimeSlot:"Sélectionnez un créneau horaire disponible",
  January:"Janvier",
  February:"Février",
  March :"Mars",
  April:"Avril",
  May:"Peut",
  June:"Juin",
  July:"Juillet",
  August:"Août",
  September:"Septembre",
  October:"Octobre",
  November:"Novembre",
  December:"Décembre",
  Monday:"Lun",
  Tuesday:"Mar",
  Wednesday:"Épouser",
  Thursday:"Jeu",
  Friday:"Ven",
  Saturday:"Assis",
  Sunday:"Soleil",
  ViewTimings: "View Timings",
  HideTimings: "Hide Timings",
  PleaseAnswerAllMandatoryQuestions:"Veuillez répondre à toutes les questions obligatoires",
  HelpLineLink: 'Helpline Link',
  ShipmentOf: "Expédition%sur",
  OtherShipments: "Autres Expéditions",
  OrderInformation:"Informations sur la commande",
  OR:"ou",
  TrackShipment:" Suivre l'expédition",
  TrackingDescription:"Entrez les informations ci-dessous pour obtenir les détails de votre envoi",
  Continue:"Continuer",
  InvalidCredentials:"Identifiants invalides. Veuillez réessayer",
  ITEM: 'article',
  ITEMS: 'article(s)',
};
export default frenchStrings;
