
import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClientSpecificStyle from "../../clientSpecificStyle";
import Banner from "../../components/Banner";
import CarouselComponent from "../../components/Carousel";
import NewHelpSection from "../../components/NewHelpSection";
import ShipmentList from "../../components/Shipment";
import OrderDetails from "../../components/Shipment/details";
import SocialMedia from "../../components/SocialMedia";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { bindActionCreators } from "redux";






class NewDesktopViewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marketingAssets: {},
            message: false,
        };
    }
    async componentDidMount() {
        this.intervalId = setInterval(this.getRefereshData.bind(this), 5 * 60000);

        if (!this.props.trackingDetails.calledFromDiy) {
            const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
            if (!isEmpty(marketingAssets)) {
                this.setState({ marketingAssets: marketingAssets });
            }
        }

    }
    hasQuickAction = () => {
        if ((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList)) ||
            (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints)) ||
            (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help) &&
                (!isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineContact) || !isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineEmail))
            )) {
            return true;
        }
        return false;
    }
    getRefereshData() {
        // in case of live-tracking and fe movement no api call.
        if (this.props.url && this.props.trackingDetails && !(this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO
            && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]
            && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]) && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList
            && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList))) {
            this.props.actions.getTrackingDetails(this.props.url, this.props.isReturn, this.props.trackingDetails);
        }
    }
    checkLandingPage = () => {
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
            let processUpdateForm = JSON.parse(this.props.trackingDetails.processUpdateForm);
            if (processUpdateForm.formList) {
                const formList = processUpdateForm.formList;
                for (let i = 0; i < formList.length; i++) {
                    if (formList[i].landingPage && formList[i].landingPage == true) {
                        return (

                            this.props.openProcessForm(i)
                        );
                    }
                }
            }
        }
        return (
            <div className="miller-knoll">
                {this.renderDeskTopView()}
            </div>
        );
    }

    renderSocialMedia = () => {

        if (
            !isEmpty(this.props.trackingDetails) ||
            !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
            mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
        ) {
            return (
                <SocialMedia
                    theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
                    layoutScreen={this.props.trackingDetails.layoutScreen}
                    secondaryBgColor={this.props.secondaryBgColor}
                    language={this.props.trackingDetails?.language || "en"}
                    fontFamily={this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"}

                />
            );
        }


        if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen) {
            let layoutScreen = this.props.trackingDetails.layoutScreen;
            if (!layoutScreen.facebook && !layoutScreen.twitter && !layoutScreen.youtube && !layoutScreen.insta && !layoutScreen.tiktok && !layoutScreen.linkedIn && !layoutScreen.pinterest
                && !(layoutScreen.extraInfo && layoutScreen.extraInfo[0] && layoutScreen.extraInfo[0].label)
                && !(layoutScreen.extraInfo && layoutScreen.extraInfo[1] && layoutScreen.extraInfo[1].label)) {
                return;
            }
        }

        return (
            <SocialMedia
                theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
                layoutScreen={this.props.trackingDetails.layoutScreen}
                secondaryBgColor={this.props.secondaryBgColor}
                language={this.props.trackingDetails?.language || "en"}
                fontFamily={this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"}

            />
        );
    };
    renderBannerAndCarsoul = () => {
        var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
        if (!isEmpty(marketingAssets)) {
            return (
                <div className="desktop-view-market-asset" style={{ padding: "0px" }} >
                    <div className="desktop-view-market-box-space" id="marketingAsset">
                        {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
                            <Banner
                                image={
                                    marketingAssets.bannerDetails.url
                                }
                                link={
                                    marketingAssets.bannerDetails.redirectUrl
                                }
                            />
                        )}
                    </div>
                </div>
            );
        };
    };
    getQuickAction = () => {
        return (
            <NewHelpSection
                trackingDetails={this.props.trackingDetails}
                openNeedHelpProcessForm={this.props.openNeedHelpProcessForm}
            />
        );
    }
    getShipmentData = (url) => {
        if (url) {
            const start = url.indexOf("tracking?") + "tracking?".length;
            const end = url.indexOf("#/");
            url = url.substring(start, end);
        }
        this.props.actions.getTrackingDetails(url, this.props.isReturn, this.props.trackingDetails, true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }
    getHeight = ()=>{
        if(this.props.universalTracking){
          let universalTracking=this.props.newCiData?.universalSearch?.universalTracking;
          if(universalTracking?.hideFooter && universalTracking?.hideHeader){
              return "100vh";
          }
          else if (universalTracking?.hideFooter || universalTracking?.hideHeader){
              return "calc(100vh - 55px)";
          }
        }
        return "";
    }
    showFooter = ()=>{
        if(this.props.universalTracking && this.props.newCiData?.universalSearch?.universalTracking?.hideFooter){
          return false;
        }
        return true;
    }
    renderDeskTopView = () => {
        const fullheight = this.props.universalTracking?"fullheight":"";
        return (
            <React.Fragment>
                <div className="ci-top">
                    <div style={{minHeight:this.getHeight()}} className={`ci-background-grey newCiBackground ${fullheight}`}>
                        <div className="desktopView ci-desktopView ">
                            <Grid container className=" pt24 p15 position-rtv " style={{ cssText: 'padding-top: 24px !important' ,fontFamily:`${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Akaya Kanadaka"},sans-serif`}}>
                                <Grid item xs={7} style={{ zIndex: "1" }}>
                                    <div className="shipmentList" style={{ width: "100%" }}>
                                        {<ShipmentList
                                            containerConstants={containerConstants}
                                            orderData={this.props.trackingDetails?.orderLevelLayoutScreenDTO}
                                            getShipmentData={this.getShipmentData}
                                            theme={this.props.trackingDetails?.customerInteractionThemeSettingsDTO}
                                            isNewLayout={this.props.trackingDetails.newLayout}
                                        />}
                                    </div>
                                    <div className="">
                                        {this.renderBannerAndCarsoul()}
                                    </div>
                                </Grid>
                                <Grid item xs={5} style={{ zIndex: "2" }}>
                                    {this.props.trackingDetails?.orderLevelLayoutScreenDTO?.attributeList && this.props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList.length > 0 && <div className="width-100 ml15 " style={{ marginLeft: "24px", marginBottom: "24px" }}>

                                        <div className="orderLevel bg-white" style={{ marginTop: "0px" }}>
                                            <p style={{ fontWeight: "700", fontSize: "16px" }}>{containerConstants.formatString(containerConstants.OrderInformation)}</p>
                                            <hr className="seprator" style={{ color: "#E8DFD5" }} />
                                            {<OrderDetails
                                                trackingDetails={this.props.trackingDetails}
                                            />}
                                        </div>
                                    </div>}
                                    <div className="ml15" style={{ width: "100%", marginLeft: "24px" }}>
                                        {
                                            this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
                                            && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction && this.hasQuickAction() &&
                                            this.getQuickAction()
                                        }
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                    {this.showFooter()&& this.renderSocialMedia()}
                </div>
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.checkLandingPage()}
            </React.Fragment>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            { ...CustomerInteractionAction},
            dispatch
        ),
    };
}
function mapStateToProps(state) {
    return {
        trackingDetails: state.customerInteractionReducer.trackingDetails,
        url: state.customerInteractionReducer.url,
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(NewDesktopViewOrder);
