import { Record } from 'immutable';

const InitialState = Record({
    universalConfig : {},
    theme: "",
    newTheme:{},
    logo:"",
    loading:true,
    universalSearchBackgroundImageUrl:"",
    ciLink:"",
    universalConfigAttributeList:{},
    aggregatedAttributeList:{},
    universalSettingType:"",
    shipmentList:[]

});

export default new InitialState();