import UNIVERSAL_SEARCH from '../../Utils/universalSearchConstants';
import universalSearchInitialState from './universalSearchInitialState';


export default function (state = universalSearchInitialState, action) {
  switch(action.type) {

    case UNIVERSAL_SEARCH.SET_UNIVERSAL_SEARCH_DATA:
      return state.set('universalConfigAttributeList', action.payload.attributeList)
                  .set('theme', action.payload.themeData)
                  .set('newTheme', action.payload.newThemeData)
                  .set('logo', action.payload.logo)
                  .set('loading',false)
                  .set('universalSearchBackgroundImageUrl', action.payload.universalSearchBackgroundImage)
                  .set("aggregatedAttributeList", action.payload.aggregatedAttributeList)
                  .set("universalSettingType", action.payload.settingType)
    case UNIVERSAL_SEARCH.SET_LOADING:
      return state.set('loading',false);
    case UNIVERSAL_SEARCH.CI_LINK:
      return state.set('ciLink',action.payload);
      case UNIVERSAL_SEARCH.SET_SHIPMENTLIST:
        return state.set("shipmentList",action.payload)
    default: 
      return state;
  }
}